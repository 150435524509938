import { Button } from "@material-ui/core";
import moment from "moment";
import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function Download(props) {
  var renderMarketValue = <ExcelColumn label="" />;
  var renderExitFee = <ExcelColumn label="" />;
  var renderServiceFee = <ExcelColumn label="" />;
  var renderNetPay = <ExcelColumn label="" />;

  if (props.status.includes("processingredemption")) {
    renderMarketValue = <ExcelColumn label="Market Value" />;
    renderExitFee = <ExcelColumn label="Exit Fee" />;
    renderServiceFee = <ExcelColumn label="Less: Service Fee" />;
    renderNetPay = <ExcelColumn label="Net Pay" />;
  }

  let name = "Investments";
  if (props.status.includes("processingredemption")) name = "Redemption";

  return (
    <ExcelFile element={props.button} filename={props.filename}>
      <ExcelSheet data={props.data} name={name}>
        <ExcelColumn label="User Account's Number" value="accountNumber" />
        <ExcelColumn label="Official Account Name" value="clientName" />
        <ExcelColumn label="Client Name" value="clientName" />
        <ExcelColumn label="Company Name" value="companyName" />
        <ExcelColumn label="Fund Name" value="fundName" />
        <ExcelColumn label="Fund Code" value="fundCode" />
        <ExcelColumn label="Total Amount" value="amountFormat" />
        <ExcelColumn label="Investment Amount" value="invAmountFormat" />
        <ExcelColumn label="Submission Date" value="subDate" />
        <ExcelColumn label="Submission Time" value="subTime" />
        <ExcelColumn label="Transaction ID" value="transId" />
        <ExcelColumn label="Date Processed" value="" />
        <ExcelColumn label="Status" value="status" />
        <ExcelColumn label="Folio Number" value="folio" />
        <ExcelColumn
          label="Shares"
          value={
            (value) => 
            value.status === "processingRedemption"
              ? value.shares
              : ""
          }
        />
        <ExcelColumn
          label="NAVPS Booking Time"
          value={(value) =>
            value.status === "processingRedemption" ? value.rateAtBooking : ""
          }
        />
        <ExcelColumn label="Fee" value="fee" />
        <ExcelColumn
          label="Bank Account Number"
          value={(value) =>
            value?.settlementDetails?.bankAccountNumber !== undefined
              ? value?.settlementDetails?.bankAccountNumber
              : "n/a"
          }
        />
        <ExcelColumn
          label="Bank Account Name"
          value={(value) =>
            value?.settlementDetails?.accountName !== undefined
              ? value?.settlementDetails?.accountName
              : "n/a"
          }
        />
        <ExcelColumn
          label="Bank Name"
          value={(value) =>
            value?.settlementDetails?.bankName !== undefined
              ? value?.settlementDetails?.bankName
              : "n/a"
          }
        />
        <ExcelColumn
          label="Bank Account Type"
          value={(value) =>
            value?.settlementDetails?.savingsAccount !== undefined
              ? value?.settlementDetails?.savingsAccount
                ? "Savings"
                : "Current"
              : "n/a"
          }
        />
        <ExcelColumn label="Transaction Image" value="transactionImage" />
        {renderMarketValue}
        {renderExitFee}
        {renderServiceFee}
        {renderNetPay}
        <ExcelColumn label="Payment Validation" value="paymentValid" />
      </ExcelSheet>
    </ExcelFile>
  );
}

export function ExportClientData(props) {
  return (
    <ExcelFile element={props.button} filename={props.filename}>
      <ExcelSheet data={props.data} name={"Export Client"}>
        <ExcelColumn label="Submission Date" value="submissionDate" />
        <ExcelColumn label="Creation Date" value="creationDate" />
        <ExcelColumn label="Account Number" value="accountNumber" />
        <ExcelColumn label="Last Name" value="lastName" />
        <ExcelColumn label="First Name" value="firstName" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="Portfolio Value" value="portfolioValue" />
        <ExcelColumn label="Client Status " value="adminStatus" />
        <ExcelColumn label="Referrer" value="referrer" />
        <ExcelColumn label="User Tier" value="tier" />
      </ExcelSheet>
    </ExcelFile>
  );
}
export function ExportApprovalRecurringData(props) {
  return (
    <ExcelFile element={props.button} filename={props.filename}>
      <ExcelSheet data={props.data} name={"Export Client"}>
        <ExcelColumn label="Registration Date" value="registrationDate" />
        <ExcelColumn label="User Name" value="userName" />
        <ExcelColumn label="User Account's Number" value="userAccount" />
        <ExcelColumn label="Email Address" value="userEmail" />
        <ExcelColumn label="Fund Name" value="fundName" />
        <ExcelColumn label="Fund Code" value="fundCode" />
        <ExcelColumn label="Amount" value="amount" />
        <ExcelColumn label="Account Name" value="accountName" />
        <ExcelColumn label="Name of Bank" value="bankName" />
        <ExcelColumn label="Bank Account Number" value="bankNumber" />
        <ExcelColumn label="Status" value="status" />
        <ExcelColumn label="Day of Month" value="dayofMonth" />
      </ExcelSheet>
    </ExcelFile>
  );
}

export function ExportTransactionData(props) {

  const formattedData = props.data.map((item) => ({
    ...item,
    processedDate: item.processedDate
      ? moment.unix(item.processedDate.seconds).format("MM/DD/YYYY")
      : "",
  }));
  
  return (
    <ExcelFile element={props.button} filename={props.filename}>
      <ExcelSheet data={formattedData} name={"Transaction Data"}>
        <ExcelColumn label="User Account's Number" value="accountNumber" />
        <ExcelColumn label="Official Account Name" value="clientName" />
        <ExcelColumn label="Client Name" value="clientName" />
        <ExcelColumn label="Company Name" value="companyName" />
        <ExcelColumn label="Fund Name" value="fundName" />
        <ExcelColumn label="Fund Code" value="fundCode" />
        <ExcelColumn label="Total Amount" value="amountFormat" />
        <ExcelColumn label="Investment Amount" value="invAmountFormat" />
        <ExcelColumn label="Submission Date" value="subDate" />
        <ExcelColumn label="Submission Time" value="subTime" />
        <ExcelColumn label="Transaction ID" value="transId" />
        <ExcelColumn label="Date Processed" value="processedDate" />
        <ExcelColumn label="Status" value="status" />
        <ExcelColumn label="Folio Number" value="folio" />
        <ExcelColumn label="Shares" value="shares" />
        <ExcelColumn label="NAVPS Booking Time" value="rateAtBooking" />
        <ExcelColumn
          label="Bank Account Number"
          value={(value) =>
            value?.settlementDetails?.bankAccountNumber !== undefined
              ? value?.settlementDetails?.bankAccountNumber
              : "n/a"
          }
        />
        <ExcelColumn
          label="Bank Account Name"
          value={(value) =>
            value?.settlementDetails?.accountName !== undefined
              ? value?.settlementDetails?.accountName
              : "n/a"
          }
        />
        <ExcelColumn
          label="Bank Name"
          value={(value) =>
            value?.settlementDetails?.bankName !== undefined
              ? value?.settlementDetails?.bankName
              : "n/a"
          }
        />
        <ExcelColumn
          label="Bank Account Type"
          value={(value) =>
            value?.settlementDetails?.savingsAccount !== undefined
              ? value?.settlementDetails?.savingsAccount
                ? "Savings"
                : "Current"
              : "n/a"
          }
        />
        <ExcelColumn label="Payment Validation" value="paymentValid" />
      </ExcelSheet>
    </ExcelFile>
  );
}

export const DownloadComponents = ({ transactionList, currentTab }) => {
  const processingTransactions = transactionList.filter(
    (transaction) => transaction.status === "processing"
  );
  const processingRedemptionTransactions = transactionList.filter(
    (transaction) => transaction.status === "processingRedemption"
  );
  function getEventName(type) {
    const formattedDateTime = moment().format("YYYY-MM-DD--HH-mm-ss");
    const eventNamePrefix = `${formattedDateTime} - `;

    return `${eventNamePrefix}${type}`;
  }

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
      <ExportTransactionData
        data={transactionList}
        filename={`${
          currentTab === "booking" ? "Bookings" : "Redemptions"
        } Transactions List - ${moment().format("MM/DD/YY - hh:mm a")}`}
        button={
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{
              color: "white",
              textTransform: "capitalize",
            }}
          >
            {`Export All ${
              currentTab === "booking" ? "Bookings" : "Redemptions"
            }`}
          </Button>
        }
      />
      {currentTab === "booking" ? (
        <Download
          data={processingTransactions}
          button={
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", textTransform: "capitalize" }}
              fullWidth
            >
              Generate Processing Excel File
            </Button>
          }
          filename={getEventName("Investments")}
          status={"processing"}
        />
      ) : (
        <Download
          data={processingRedemptionTransactions}
          button={
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", textTransform: "capitalize" }}
              fullWidth
            >
              Generate Processing Redemption Excel File
            </Button>
          }
          filename={getEventName("Redemption")}
          status={"processingredemption"}
        />
      )}
    </div>
  );
};
