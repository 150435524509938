import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from "react-router-dom";
import { compose } from 'recompose';
import { Box, Button, CircularProgress, Grid, Paper, useMediaQuery, useTheme,} from '@material-ui/core';
import moment from "moment";
import axios from 'axios';

import Navigation from '../../Navigation';
import { withFirebase } from "../../Firebase";
import { DashboardHeader, SummaryBox } from "../common";
import { useStyles } from '../Analytics/AnalyticsDashboard';
import withAuthorization from '../../Session/withAuthorization';
import * as ROUTES from "../../../constants/routes";
import Helpers from "../../Helpers";
import { RequestUploadPage } from '../../Operations/requestUpload';
import ModalInstruction from './ModalInstruction';

const ActionsDashboard = (props) => {
    const classes = useStyles()
    // // const responsive = useMediaQuery("(max-width:2000px)");
    const helpers = new Helpers()
    // const [showMore, setShowMore] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [processDate, setProcessDate] = useState("")
    const [application, setApplication ] = useState({
        short:0,
        full:0,
        pendingZoom:0,
        toKYC:0
    })
    const [transaction, setTransaction ] = useState({
        oneTime:0,
        recurring:0,
        bpi:0,
        redemption:0
    })
    const [dormant, setDormant] = useState(0)

       const applicationCategory = [
         {
           id: 1,
           title: "Short KYC",
           subtitle: "(Tier 1 to Tier 2)",
           value: application.short,
           routes: ROUTES.USERS,
           params: 1,
         },
         {
           id: 2,
           title: "Long KYC",
           subtitle: "(Tier 2 to Tier 3)",
           value: application.full,
           routes: ROUTES.USERS,
           params: 2,
         },
         {
           id: 3,
           title: "Pending Verification",
           subtitle: "(Zoom or Attestation)",
           value: application.pendingZoom,
           routes: ROUTES.USERS,
           params: "pending",
         },
         {
           id: 9,
           title: "Dormant",
           subtitle: "Did Not Complete KYC",
           value: dormant,
           routes: ROUTES.USERS,
           params: "dormant",
           color: "black",
         },
        //  ...(application.toKYC === 0
        //    ? []
        //    : [
        //        {
        //          id: 4,
        //          title: "Push to Full KYC",
        //          subtitle: "(Near to Investment Limit)",
        //          value: application.toKYC,
        //          routes: ROUTES.GRADUATING_USERS,
        //          params: "",
        //          color: "red",
        //        },
        //      ]),
        //  {
        //    id: 4,
        //    title: "Push to Full KYC",
        //    subtitle: "(Near to Investment Limit)",
        //    value: application.toKYC,
        //    routes: ROUTES.GRADUATING_USERS,
        //    params: "",
        //    color: "red",
        //  },
       ];
       const transactionCategory = [
         {
           id: 5,
           title: "One-Time Investments",
           value: transaction.oneTime,
           routes: ROUTES.TRANSACTION_LIST,
           params: "processing",
           tab: "booking",
         },
         {
           id: 6,
           title: "Recurring Investments",
           value: transaction.recurring,
           routes: ROUTES.RECURRING_TRANSACTIONS,
           params: "processing",
         },
         {
           id: 7,
           title: "BPI ADA Registrations",
           value: transaction.bpi,
           routes: ROUTES.RECURRING_INVESTMENTS,
           params: "processing",
         },
         {
           id: 8,
           title: "Redemption Request",
           value: transaction.redemption,
           routes: ROUTES.TRANSACTION_LIST,
           params: "processingRedemption",
           tab: "redemption",
           color:  "red",
         },
       ];

        useEffect(() => {
          getTodoListCount();
          getDormant();
        }, []);

  const getDormant= () => {
    let count = 0;
     props.firebase.db
       .collection("users")
       .where("role", "==", "client") 
       .get() 
       .then((querySnapshot) => {
         querySnapshot.forEach((doc) => {
          const data = doc.data();
          const userStatus = data?.stats?.status;
          const datekyc = parseInt(
            (moment().unix() - data?.dateKYCCompleted?.seconds) / 86400
          );
            if ("declinedStatus" in data) {
               if (data.declinedStatus === "approved") {
                if (
                  userStatus === "LONG_KYC-4" 
                ) {
                  if (datekyc >= 31) {
                    count += 1;
                  }
                }
                
              } else if (data.declinedStatus !== "returned") {
                if (datekyc >= 31) {
                  count += 1;
                } 
              }
            }
            const tier = data?.stats?.tier ?? 3;
            if (
              data?.stats?.tierUpgradeStatus &&
              data?.stats?.tierUpgradeStatus === "pending" &&
              tier === 2
            ) {
              if (
                data?.stats?.status === "LONG_KYC-10" ||
                data?.stats?.status === "LONG_KYC-11" ||
                data?.stats?.status === "LONG_KYC-12" ||
                data?.stats?.status === "LONG_KYC-9"
              ) {
                if (datekyc >= 31) {
                  count += 1; 
                } 
              } else if (
                data?.stats?.status === "LONG_KYC-7" ||
                data?.stats?.status === "LONG_KYC-6"
              ) {
                if (datekyc >= 31) {
                  count += 1;
                } 
              } 
            }
             if (
               data?.stats?.tierUpgradeStatus &&
               data?.stats?.tierUpgradeStatus === "returned"
             ) {
               if (
                 userStatus === "LONG_KYC-10" ||
                 userStatus === "LONG_KYC-11" ||
                 userStatus === "LONG_KYC-9" ||
                 userStatus === "LONG_KYC-12"
               ) {
                 if (datekyc >= 31) {
                   count += 1;
                 }
               } else if (
                 userStatus === "LONG_KYC-7" ||
                 userStatus === "LONG_KYC-6"
               ) {
                 if (datekyc >= 31) {
                   count += 1;
                 }
               }
             }
            setDormant(count)
         });
       })
       .catch((error) => {
         alert(error)
       });
  }
        
 const getTodoListCount = () => {
    return props.firebase.db
      .collection("notificationCountsAdmin")
      .doc("actionDashboard")
      .onSnapshot((snapShot) => {
        const processingDate = new moment.unix(
          snapShot.data()?.dateUpdated.seconds
        ).format("MM/DD/YYYY, hh:mm a");
        setProcessDate(processingDate);
        const snapShotData = snapShot.data();
        const onetime = snapShotData["T-oneTime"];
        const recurring = snapShotData["T-recurring"];
        const autodebit = snapShotData["T-autodebit"];
        const redemption = snapShotData["T-redemption"];

        const pendingTier1 = snapShotData["CA-pendingTier1"];
        const pendingTier2 = snapShotData["CA-pendingTier2"];
        const pendingZoom = snapShotData["CA-pendingZoom"];
        const graduating = snapShotData["CA-graduating"];

        setTransaction({
          oneTime: onetime ? onetime : 0,
          recurring: recurring ? recurring : 0,
          bpi: autodebit ? autodebit : 0,
          redemption: redemption ? redemption : 0,
        });
        setApplication({
          short: pendingTier1 ? pendingTier1 : 0,
          full: pendingTier2 ? pendingTier2 : 0,
          pendingZoom: pendingZoom ? pendingZoom : 0,
          toKYC: graduating ? graduating : 0,
        });
      });
  }
 const refreshListCount = async () => {
   const apiUrl = `${ROUTES.FUNCTIONAL_BASE_URL}operations-operations/refresh-task-list`;
   const token = helpers.getCookie("token");
   try {
     setLoading(true);
     await axios.get(apiUrl, {
       headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${token}`,
       },
     });
     setLoading(false);
   } catch (error) {

     setLoading(false);
   }
 };


    const itemCard = (header, isApplication) => {
        let category = transactionCategory; 
        if (isApplication) {
            category = applicationCategory
            // category = applicationCategory.length >= 5 && !showMore ? applicationCategory.slice(0,4) : applicationCategory
        };
          // const handleSeeMore = () => {
          //   setShowMore((prevState) => 
          //     !prevState
          //   );
          // };    
        return (
          <Grid item xs={12}>
            <Paper style={{ padding: "10px 20px 10px 20px" }} elevation={3}>
              <h3 className="text-lg text-center">{header}</h3>
              <Box
                display="flex"
                flexDirection="row"
                gridGap="10px"
                height="100%"
                width="97%"
              >
                {category.map((item, index) => {
                  const handleNavigate = () => {
                    switch (item.id) {
                      case 1:
                        props.history.push({
                          pathname: item.routes,
                          state: { from: "DB", status: item.params },
                        });
                        break;
                      case 2:
                        props.history.push({
                          pathname: item.routes,
                          state: { from: "DB", status: item.params },
                        });
                        break;
                      case 3:
                        props.history.push({
                          pathname: item.routes,
                          state: { from: "DB", status: item.params },
                        });
                        break;
                      case 4:
                        props.history.push(`${item.routes}`);
                        break;

                      case 5:
                        props.history.push(
                          `${item.routes}?status=${item.params}&tab=${item?.tab}`
                        );
                        break;
                      case 6:
                        props.history.push(
                          `${item.routes}?status=${item.params}`
                        );
                        break;
                      case 7:
                        props.history.push(
                          `${item.routes}?status=${item.params}`
                        );
                        break;
                      case 8:
                        props.history.push(
                          `${item.routes}?status=${item.params}&tab=${item?.tab}`
                        );
                        break;
                      case 9:
                        props.history.push({
                          pathname: item.routes,
                          state: { from: "DB", status: item.params },
                        });
                        break;

                      default:
                        break;
                    }
                  };

                  return (
                    <Grid item lg={3} xs={3}>
                      <Paper
                        elevation={1}
                        className={item.value !== 0 && classes.itemHover}
                        onClick={item.value !== 0 && handleNavigate}
                        key={index}
                      >
                        <div className="p-3 text-center">
                          <span
                            style={{
                              color: `${item.color ? item.color : "#C8932A"}`,
                              fontSize: "1.5rem",
                              lineHeight: "2rem",
                              fontWeight: 600,
                            }}
                          >
                            {item.value}
                          </span>
                          <p
                            className={`font-extrabold px-1`}
                            style={{
                              fontSize: "0.938rem",
                              lineHeight: "1.5rem",
                            }}
                          >
                            {item.title}
                          </p>
                          {isApplication && (
                            <p className=" text-xs font-extrabold pt-0.5">
                              {item.subtitle}
                            </p>
                          )}
                        </div>
                      </Paper>
                    </Grid>
                  );
                })}
                {/* {isApplication && (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      width: "120px",
                      height: "30px",
                      margin: "35px 0px 20px 0px",
                      textTransform: "none",
                      fontWeight: 600,
                    }}
                    onClick={handleSeeMore}
                  >
                    {showMore ? "See Less" : "See More"}
                  </Button>
                )} */}
              </Box>
            </Paper>
          </Grid>
        );
    }
 const uploadExcel = (name) => {
   return (
     <Grid item lg={6}>
       <div className=" text-lg text-center mb-2">{name}</div>
      <RequestUploadPage name={name}/>

     </Grid>
   );
 };
    return (
      <div className={"dashboardRoot"}>
        <div className={"dashboardMasterContainer"}>
          <span className={"dashboardLabel"}>Action Dashboard</span>
          {modalOpen && (
            <ModalInstruction
              isOpen={modalOpen}
              isClose={() => setModalOpen(false)}
            />
          )}
          <Grid container spacing={4} style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <SummaryBox />
            </Grid>
            <Grid item xs={12}>
              <DashboardHeader
                title={"Task Overview"}
                date={processDate}
                enableRefresh
                onRefresh={refreshListCount}
              />
              {loading ? (
                <Grid item xs={12}>
                  <div className=" text-center">
                    <CircularProgress />
                    <div style={{ marginTop: "10px" }}>Loading...</div>
                  </div>
                </Grid>
              ) : (
                <>
                  {itemCard("Client Application Tasks", true)}
                  <br />
                  {itemCard("Transaction Tasks", false)}
                  <br />
                  <Grid item xs={12}>
                    <Paper style={{ padding: "10px" }} elevation={3}>
                      <h3 className="text-lg text-center">
                        Upload Transactions
                      </h3>
                      <Box
                        display="flex"
                        flexDirection="row"
                        gridGap="20px"
                        height="100%"
                      >
                        {uploadExcel("Investment Transactions")}
                        {uploadExcel("Redemption Transactions")}
                      </Box>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            color: "white",
                            textTransform: "none",
                            marginTop: "20px",
                            padding: "10px 70px 10px 70px",
                          }}
                          onClick={() => setModalOpen(true)}
                        >
                          View Instruction
                        </Button>
                      </div>
                    </Paper>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
}

const ActionsPage = () => {
    return (
        <>
            <Navigation content={<Actions />} />
        </>
    );
};

const condition = (authUser) => !!authUser;

const Actions = compose(
    withAuthorization(condition),
    withRouter,
    withFirebase
)(ActionsDashboard)

export default ActionsPage;
